/* poppins-regular - latin - regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/poppins/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../assets/fonts/poppins/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/poppins/poppins-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/poppins/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin - italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/poppins/poppins-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''), url('../assets/fonts/poppins/poppins-v15-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/poppins/poppins-v15-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/poppins/poppins-v15-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin - medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/poppins/poppins-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('../assets/fonts/poppins/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/poppins/poppins-v15-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/poppins/poppins-v15-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin - semibold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/poppins/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../assets/fonts/poppins/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/poppins/poppins-v15-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/poppins/poppins-v15-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/poppins/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
